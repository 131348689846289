

  .header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;
    transition: all 0.5s ease;
  }
  
  .header.fixed {
    background-color: #ffffff;
    position: fixed;
  }
  
  .header .brand-name {
    padding: 0 15px;
  }
  
  .header .brand-name a {
    text-decoration: none;
    font-size:20px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 2px;
    transition: all 0.5s ease;
  }
  
  .header.fixed .brand-name a {
    color: #000000;
  }
  
  .header .nav-toggle {
    width: 40px;
    height: 34px;
    border: 1px solid #ffffff;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    display: none;
  }
  
  .header.fixed .nav-toggle {
    border-color: #000000;
  }
  
  .header .nav-toggle span {
    height: 1px;
    display: block;
    width: 16px;
    background-color: #ffffff;
    position: relative;
    transition: all 0.5s ease;
  }
  
  .header .nav-toggle span:before,
  .header .nav-toggle span:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 1px;
    width: 100%;
    background-color: #ffffff;
    transition: all 0.5s ease;
  }
  
  .header.fixed .nav-toggle span,
  .header.fixed .nav-toggle span:before,
  .header.fixed .nav-toggle span:after {
    background-color: #000000;
  }
  
  .header .nav-toggle span:before {
    transform: translateY(-6px);
  }
  
  .header .nav-toggle span:after {
    transform: translateY(6px);
  }
  
  .header .nav {
    padding: 0 15px;
  }
  
  .header .nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .header .nav ul li {
    display: inline-block;
    margin-left: 30px;
  }
  
  .header .nav ul li a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    padding: 25px 0;
    display: block;
    transition: all 0.5s ease;
  }
  
  .header.fixed .nav ul li a {
    color: #000000;
  }
  
  .header.fixed .nav ul li a:hover,
  .header .nav ul li a:hover {
    color: #E24C4F;
  }


  .brand-name {
    width: 140px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center; /* Horizontally center the content */
  }
  
  .brand-name a.text {
    display: inline; /* Display the anchor element inline */
    white-space: nowrap; /* Prevent line break between words */
    font-size: 20px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 2px;
    transition: all 0.5s ease;
  }
  
  .brand-name img.logo {
    width: 50px;
    height: 55px;
    margin-right: 10px; /* Adjust the margin as needed */
  }








  
/*responsive*/

@media(min-width: 768px){
	.header .nav{
		display: block!important;
	}
}
@media(max-width: 767px){
	.header{
		padding:7px 0;
		position: fixed;
	}
	.header .nav-toggle{
		display: flex;
		margin-right: 15px;
	}
	.header .nav{
		flex:0 0 100%;
		max-width: 100%;
		background-color: #ffffff;
		padding:0;
		display: none;
	}
	.header.fixed .nav{
      border-top:1px solid #cccccc;
	}
	.header .nav ul li{
		display: block;
		margin-right: 25px;
	}
	.header.fixed .nav ul li a,
	.header .nav ul li a{
		color:#000000;
		padding: 15px 0;
	}
	.home-section .home-content h1{
		font-size: 40px;
	}
	.about-section .about-img{
		margin-bottom: 30px;
	}
	.about-section .about-content,
	.about-section .about-img {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .work-section .work-item,
    .service-section .service-item{
    	flex: 0 0 50%;
    max-width: 50%;
    }
}




