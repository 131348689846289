/* Header.css */
body {
  margin: 0;
  line-height: 1.5;
  background-color:  #f3f2f1;
  
}

body,
input,
textarea,
button {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
}

* {
  box-sizing: border-box;
  margin: 0;
}

.container {
  max-width: 1170px;
  margin: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

@keyframes ani01 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.section-title {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 60px;
  padding: 0 15px;
}

.section-title h1 {
  display: inline-block;
  font-size: 40px;
  color: #000000;
  font-weight: 700;
  margin: 0;
  position: relative;
}

.section-title h1:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 140px;
  height: 3px;
  background-color: #E24C4F;
}

.btn-01 {
  background-color: #E24C4F;
  color: #ffffff;
  border: 2px solid transparent;
  padding: 10px 35px;
  border-radius: 30px;
  line-height: 1.5;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  transition: all 0.5s ease;
}

.btn-01:hover {
  background-color: transparent;
  border-color: #E24C4F;
  color: #E24C4F;
}

.btn-01:focus {
  outline: none;
}

/* preloader */
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #111111;
  z-index: 1099;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s ease;
}

.preloader.loaded {
  visibility: hidden;
  opacity: 0;
  z-index: -99;
}

.preloader .loader {
  height: 30px;
  width: 30px;
  border: 2px solid #E24C4F;
  border-right: 2px solid transparent;
  border-radius: 50%;
  animation: ani01 2s linear infinite;
}

@media(min-width: 768px){
	.header .nav{
		display: block!important;
	}
}
@media(max-width: 767px){
	.header{
		padding:7px 0;
		position: fixed;
	}
	.header .nav-toggle{
		display: flex;
		margin-right: 15px;
	}
	.header .nav{
		flex:0 0 100%;
		max-width: 100%;
		background-color: #ffffff;
		padding:0;
		display: none;
	}
	.header.fixed .nav{
      border-top:1px solid #cccccc;
	}
	.header .nav ul li{
		display: block;
		margin-right: 25px;
	}
	.header.fixed .nav ul li a,
	.header .nav ul li a{
		color:#000000;
		padding: 15px 0;
	}
	.home-section .home-content h1{
		font-size: 40px;
	}
	.about-section .about-img{
		margin-bottom: 30px;
	}
	.about-section .about-content,
	.about-section .about-img {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .work-section .work-item,
    .service-section .service-item{
    	flex: 0 0 50%;
    max-width: 50%;
    }
}

@media(max-width: 575px){
.home-section .home-content h1{
		font-size: 25px;
		letter-spacing: 5px;
	}
	.about-section .about-content .stats .stat-box {
      flex: 0 0 50%;
      max-width: 50%;
   }
   .contact-section .contact-info .info-item,
   .contact-section .contact-form .form .input-group.left, 
   .contact-section .contact-form .form .input-group.right,
   .work-section .work-item,
   .service-section .service-item{
    	flex: 0 0 100%;
        max-width: 100%;
    }
    .contact-section .contact-form{
    	padding: 0px 35px;
    }
    .contact-section .contact-form .form{
    	padding: 55px 5px;
    }
}



